import React, { useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery"
import { useMediaQuery } from "react-responsive";
import {useCookies} from "react-cookie";
import Pagination from "./components/Pagination";
import icon_new from "images/icon/icon_new.png";
import m_icon_new from "images/m/icon_new.png";
import icon_paperclip from "images/icon/icon_paperclip.png";
import m_icon_paperclip from "images/m/icon_paperclip.png";
import icon_download from "images/icon/icon_download.png";
import m_icon_download from "images/m/icon_download.png";
import axios from "axios";
import Moment from "moment";
import { BeatLoader } from "react-spinners";

const NoticePage = (props) => {
  const isPc = useMediaQuery({ query: "(min-width: 960px)" });
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [showDetail, setShowDetail] = useState(0);
  const [notices, setNotices] = useState([]);
  const [lists, setLists] = useState([]);
  const [cookies, setCookie] = useCookies(["NOTICE_:id"])
  const [checked, setChecked] = useState(false);

  useEffect(()=>{
    $(function () {
      $(".list_title_wrap").on("click", function () {
        $(".text_wrap").not($(this).siblings(".text_wrap")).hide();
        $(this).siblings(".text_wrap").toggle();
      });
    });
  }, [])

  useEffect(() => {
    const fetchLists = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/board/list/Notice`
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/board/list/Notice?start=0&length=${res.data.recordsTotal}`
      );
      makeDatas(response.data);

      if (noticeId) {
        viewDetail(Number(noticeId))
      } else {
        setChecked(true);
      }
    };
    const makeDatas = (lists) => {
      const filterNotice = lists.data.filter((el) => el.is_notice === true);
      const filterLists = lists.data.filter((el) => el.is_notice === false);
      setNotices(filterNotice);
      setLists(filterLists);
    };
    fetchLists();
  }, []);

  const LIMIT = 10;
  const totalData = lists.length;

  const pagenatedData = (page, limit) => {
    const firstIndex = page * limit - limit;
    const lastIndex = page * limit;
    return lists.slice(firstIndex, lastIndex);
  };
  const renderedData = pagenatedData(currentPage, LIMIT);
  const indexOfLastPosts = currentPage * postPerPage;
  const indexOfFirstPosts = indexOfLastPosts - postPerPage;
  const currentPosts = lists.slice(indexOfFirstPosts, indexOfLastPosts);
  const params = new URLSearchParams(props.history.location.search);
  const noticeId = params.get("id");

  const isItNew = (createdAt) => {
    const afterSeven = Moment(createdAt).add(7, "days").format("YYYY-MM-DD");
    const now = Moment().format('YYYY-MM-DD')

    return !Moment(now).isAfter(afterSeven)
  };


  const viewDetail = async (id) => {
    if(showDetail == id){
      setShowDetail(0);
    }else {
      setShowDetail(id);
    }
    let now = new Date;
    let afterHour = new Date();
    afterHour.setMinutes(now.getMinutes() +60);
    setCookie(`NOTICE_${id}`, id, {path: '/notice', expires: afterHour});
    if(cookies[`NOTICE_${id}`] === undefined){
      await axios.get(`${process.env.REACT_APP_API_URL}api/board/hit/Notice/${id}`, { withCredentials: true })
    }
    setChecked(true);
  };

  return (
    <NoticeContainer className="board_wrap">
      <div className="wrap_title board_title">
        <h2>공지사항</h2>
      </div>
      <div className="notice_wrap">
        <ul>
          {notices.map((list) => {
            return(
                <li key={list.id}>
                  <div className="list_title_wrap" onClick={(e) => {
                      viewDetail(list.id);
                    }}>
                    <p className="num_wrap">
                      <span className="ico_notice">공지</span>
                    </p>
                    <p className="notice_title">
                      <span className="title notice">{list.title}</span>
                      {list.file ? (
                        <span className="clip">
                          <img src={isPc ? icon_paperclip : m_icon_paperclip} alt="첨부파일" />
                        </span>
                      ) : null}
                      {isItNew(list.createdAt) ? (
                        <span className="new">
                          <img src={isPc ? icon_new : m_icon_new} alt="new" />
                        </span>
                      ) : null}
                    </p>
                    <p className="count">{list.hit}</p>
                  </div>

                  {showDetail === list.id ? (
                    <div className="text_wrap">
                      <div>
                          <div class="board_cont" dangerouslySetInnerHTML={ {__html: list.content.replace(/(<? *script)/gi, 'illegalscript')}}/>
                            {list.file === "" ? null :
                                <a href={`${process.env.REACT_APP_API_URL}api/download?url=`+list.file+`&filename=`+list.filename} download={list.file.replace(/^.*[\\\/]/, '')}>
                                  <span className="attachment">
                                    {list.filename}
                                  </span>
                                </a>
                            }
                      </div>
                    </div>
              ) : null}
            </li>
              )
            }
          )}
        </ul>
        <ul>
          {checked ? (currentPosts.length === 0 ? (
            <p className="noList">등록된 게시물이 없습니다.</p>
          ):(
            renderedData.map((list, idx) => (
              <li key={list.id}>
                <div
                  className="list_title_wrap"
                  onClick={(e) => {
                    viewDetail(list.id);
                  }}
                >
                  <p className="num_wrap">
                    <span className="num">{totalData -idx -(LIMIT*(currentPage-1)) }</span>
                  </p>
                  <p className="notice_title">
                    <span className="title">{list.title}</span>
                    {list.file ? (
                      <span className="clip"><img src={isPc ? icon_paperclip : m_icon_paperclip} alt="첨부파일" /></span>
                    ) : null}
                    {isItNew(list.createdAt) ? (
                      <span className="new"><img src={isPc ? icon_new : m_icon_new} alt="new" /></span>
                    ) : null}
                  </p>
                  <p className="count">{list.hit}</p>
                </div>
                {showDetail == list.id ? (
                  <div className="text_wrap">
                    <div>
                      <div class="board_cont" dangerouslySetInnerHTML={ {__html: list.content.replace(/(<? *script)/gi, 'illegalscript')}}/>
                      {list.file === "" ? null :
                          <a href={`${process.env.REACT_APP_API_URL}api/download?url=`+list.file+`&filename=`+list.filename} download={list.file.replace(/^.*[\\\/]/, '')}>
                            <span className="attachment">
                              {list.filename}
                            </span>
                          </a>}
                    </div>
                  </div>
                ) : null}
              </li>
            ))
          )) : (
            <div className="noList">
              <h4 style={{marginBottom: "20px"}}>잠시만 기다려주세요.</h4>
              <BeatLoader />
            </div>)}
        </ul>
      </div>
      <ul className="reviewChat_cover_ul">
        <Pagination
            postsPerPage={10}
            totalPosts={
              totalData
            }
            paginate={setCurrentPage}
            currentpage={currentPage}
        ></Pagination>
      </ul>
    </NoticeContainer>
  );
};

const NoticeContainer = styled.div`
  & .list_title_wrap {
    display: flex;
    align-items: center;
    height: 75px;
    padding: 0 20px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &:hover {
      background: #f5fafc;
    }
    & .num_wrap {
      flex-basis: 110px;
      flex-shrink: 0;
      & span {
        display: inline-block;
        width: 60px;
        text-align: center;
        font-size: 0.875rem;
        color: #515355;
        line-height: 21px;
        &.ico_notice {
          padding: 3px 17px;
          background: #fff;
          color: #0065ab;
          border: 1px solid #0065ab;
          border-radius: 30px;
          font-weight: 700;
          line-height: 21px;
          word-break: keep-all;
        }
      }
    }

    & .notice_title {
      width: calc(100% - 190px);
      & .title {
        display: inline-block;
        overflow: hidden;
        max-width: calc(100% - 60px);
        width: auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.125rem;
        line-height: 27px;
        color: #222;
        &.notice {
          font-weight: 500;
        }
      }

      & .clip {
        margin-left: 2px;
        img {
          width: 20px;
          vertical-align: top;
          margin-top: 5px;
        }
      }

      & .new {
        margin-left: 4px;
        img {
          width: 22px;
          vertical-align: top;
          margin-top: 3px;
        }
      }
    }
    & .count {
      display: inline-block;
      width: 80px;
      margin-left: auto;
      text-align: center;
      color: #515355;
      font-size: 0.875rem;
      line-height: 21px;
    }
  }

  & .text_wrap {
    padding: 20px 10px;
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;

    & > div {
      overflow-y: auto;
      max-height: 535px;
      padding: 40px 120px;

      &::-webkit-scrollbar {
        width: 6px;
        margin-right: 10px;
      }
      &::-webkit-scrollbar-thumb {
        height: auto;
        background-color: #ccc;
        border-radius: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    img {
      width: 100%;
      margin-bottom: 30px;
    }

    p {
      font-size: 1rem;
      line-height: 24px;
      color: #222;
      word-break: break-all;
    }

    & a {
      display: inline-block;
      max-width: 100%;
    }

    .attachment {
      display: inline-block;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
      margin-top: 30px;
      padding: 12px 23px 12px 47px;
      border: 1px solid #ddd;
      border-radius: 4px;
      background: #fff url(${icon_download}) no-repeat left 23px center;
      color: #515355;
      line-height: 21px;
      font-size: 0.875rem;
      font-weight: 500;
    }
  }
  
  & .text_wrap_hide {
    display:none;
  }

  @media only screen and (max-width: 960px) {
    & .notice_wrap {
      border-top: 2px solid #555;
    }
    & .list_title_wrap {
      height: 65px;
      padding: 0;
      &:hover {
        background: #fff;
      }
      & .num_wrap {
        flex-basis: auto;
        flex-shrink: 1;
        & span {
          width: auto;
          font-size: 0.75rem;
          &.ico_notice {
            width: 45px;
            padding: 3px 11px;
            line-height: 18px;
          }
          &.num {
            display: none;
          }
        }
      }

      & .notice_title {
        overflow: hidden;
        width: 100%;
        & .title {
          margin-left: 0;
          max-width: calc(100% - 53px);
          font-size: 1rem;
          line-height: 24px;
          &.notice {
            margin-left: 8px;
          }
        }

        & .clip {
          img {
            width: 20px;
            margin-top: 4px;
          }
        }

        & .new {
          img {
            width: 18px;
            margin-top: 4px;
          }
        }
      }
      & .count {
        display: none;
      }
    }

    & .text_wrap {
      padding: 20px;
      background: #f8f8f8;
      border-bottom: 1px solid #ddd;

      & > div {
        overflow-y: auto;
        padding: 0;
      }

      img {
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
      }

      p {
        font-size: 0.875rem;
        line-height: 21px;
      }

      .attachment {
        margin-top: 16px;
        padding: 12px 14px 12px 41px;
        background: #fff url(${m_icon_download}) no-repeat left 14px center;
        background-size: 15px 15px;
        line-height: 19px;
        font-size: 0.813rem;
      }
    }
  }
`;

export default NoticePage;
