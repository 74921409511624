import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { stopReportingRuntimeErrors } from "react-error-overlay";
import "./index.css";
import { Provider } from 'react-redux';
import store from "./store";
import Root from "./Root";
window.process = {};

if (process.env.NODE_ENV === "development") {
    stopReportingRuntimeErrors(); // disables error overlays
}

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    document.getElementById('root')
);

