import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import 'rc-rate/assets/index.css';
import Button from './Button';
import Dialog from './Dialog';
import arr_more from "images/arr_more.png";
import { BsDot } from 'react-icons/bs';
import { GrPrevious } from 'react-icons/gr';
import { GrNext } from 'react-icons/gr';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { RiArrowRightSLine } from 'react-icons/ri';
import {stringPrice} from "utils/StringPrice"
import RadioBox from './RadioBox';
import PostCode from "./PostCode";
import sublist_open from 'images/icon/sublist_open.png';
import m_sublist_open from 'images/m/m_sublist_open.png';
import { useForm  } from "react-hook-form";
import axios from "axios";
import {useSelector} from "react-redux";

function ReqRefundPopup({ visible, setReqRefund, product, onCancel }) {
  const isPc = useMediaQuery({ query: '(min-width: 961px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const {register, handleSubmit, setValue, watch} = useForm();
  const userInfo = useSelector((state) => state.userLogin.userInfo.data || "");
  const [postCodePopup, setPostCodePopup] = useState(false);
  const {orderId, item, idx} = product
  const OrderProductsId = product.item.OrderProducts

  const isproduct = !product.item.RegularOrderId
  const info = isproduct ? product?.item?.products : product.item.product

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState([]);

  const inputChecked = useRef(0)

  const selectItemsId = (e, id ) => {
    let checked = e.target.checked
    let selectedOrderId = OrderProductsId.filter((el) => el.ProductId === id)[0]
    if(checked){
      setSelectedProduct((value) =>[...value, selectedOrderId.id])
    }else{
      setSelectedProduct(selectedProduct.filter((el) => el !== selectedOrderId.id ))
    }
  }

  const checkedHandler = (id) => {
    return selectedProduct.indexOf(id) > -1
  }

  const selectedItemsName = (e, name ) => {
    let checked = e.target.checked
    if(checked){
      setSelectedProductName((value) =>[...value, name])
    }else{
      setSelectedProductName(selectedProductName.filter((el) => el !== name))
    }
  }
  const checkReason = watch("refund_content");
  const checkReasonSel = watch("refund_select");
  const checkBank_owner = watch("bank_owner");
  const checkBank_number = watch("bank_number");
  const checkBank_name = watch("bank_name");
  const checkUserName = watch("userName");
  const checkPhone = watch(["p1", "p2", "p3"]);
  const checkAddress = watch(["address", "addressDetail"])

  const onSubmit = async (data) => {
    data = {
      product_name: [...selectedProductName],
      OrderProductId: [...selectedProduct],
      phone : data.p1 + data.p2 + data.p3,
      status_sort : selectedVal,
      bank_account: data.bank_number,
      return_bank: data.bank_name,
      account_owner: data.bank_owner,
      return_reason: data.refund_select,
      reason_content: data.refund_content,
      return_way: "롯데택배",
      return_status: selectedVal === "반품" ? "반품신청" : "교환신청",
      name: data.userName,
      place_address: `${data.address} / ${data.addressDetail}`
    }

    try{
      axios.defaults.headers.common["cid"] = `${userInfo.cid}`
      axios.defaults.headers.common["encoded"] = `${userInfo.encoded}`
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/user/return`, {data : data})
        setStep1(true);
        setStep2(false);
        setStep3(false);
        setReqRefund(false);
        onCancel();
    }catch(error){
      console.log(error)
    }
  };

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  const showStep1 = () => {
    setStep1(true);
    setStep2(false);
    document.querySelector(".dialog_inner").scrollTo(0,0);
  }

  const showStep2 = () => {
    if(selectedVal !== "" && selectedProduct.length !== 0){
      setStep1(false);
      setStep2(true);
    }else{
      return
    }
    document.querySelector(".dialog_inner").scrollTo(0,0);
  }

  const showStep3 = () => {
    if(item.pay_method === "card"){
      if(checkReason !== "" && checkReasonSel !== ""){
        setStep2(false);
        setStep3(true);
      }else{
        return
      }
    }else{
      if(checkReason !== "" && checkReasonSel !== ""
        && checkBank_owner !== "" && checkBank_number !== "" && checkBank_name !== ""){
        setStep2(false);
        setStep3(true);
      }else{
        return
      }
    }

    document.querySelector(".dialog_inner").scrollTo(0,0);
  }


  const onCancel2 = () => {
    setStep1(true);
    setStep2(false);
    setStep3(false);
    setReqRefund(false);
  }

  const [selectedVal, setSelectedVal] = useState("");
  const handleChange = (e) => {
    setSelectedVal(e.target.value)
  }

  return (
    <ReqRefundDialog visible={visible}>
      <Dialog
        title="반품신청"
        cancelText="닫기"
        onCancel={onCancel2}
        dialogSize="medium"
        fullHeight
        fixHeight
        visible={visible}
      >
          <div className="process">
            <ul>
              <li className={step1 ? "step1 active" : "step1"}>교환/반품 상품 선택</li>
              <li className={step2 ? "step2 active" : "step2"}>교환/반품신청</li>
              <li className={step3 ? "step3 active" : "step3"}>교환/반품 방법 선택</li>
            </ul>
          </div>
          {isMobile && <p className="noArea"></p>}
          <div className={step1 ? "step1_wrap custom_scroll active" : "step1_wrap custom_scroll"}>
            <div className="step_box">
                  <div className="req_type_wrap">
                    <RadioBox
                      label = "교환 신청"
                      id="chk_exchange"
                      name="req_type"
                      value="교환"
                      onChange={(e) => {handleChange(e);}}
                      checked={selectedVal === "교환"}
                      selectedVal = {selectedVal}
                    />
                    <RadioBox
                      label = "반품 신청"
                      id="chk_refund"
                      name="req_type"
                      value="반품"
                      onChange={(e) => {handleChange(e);}}
                      checked={selectedVal === "반품"}
                      selectedVal = {selectedVal}
                    />
                </div>
              <p className="sub_tit">교환/반품 상품 선택</p>
              <div className="order_lists">
                <ul>
                  <li className="order_lists_list">
                    <ul>
                      {isproduct && item.OrderProducts.map((el, idx) => {
                        return el.return_status ? null : <li className="order_list_content" key={idx}>
                          <div className="order_list_content_in">
                            <div className="checks full_check">
                              <input type="checkbox" id={el.ProductId} onClick={(e) => {selectItemsId(e, el.ProductId); selectedItemsName(e, el.ordered_product_name);}}
                                     checked={null} disabled={false} />
                              <label htmlFor={el.ProductId}></label>
                            </div>
                            <div className="order_list_content_img">
                              <img src={el.Product.main_img} alt="제품이미지"/>
                              <div className="order_list_content_p">
                                <p className="order_title">{el.ordered_product_name}</p>
                                <p className="order_option">{el.ordered_option_name}</p>
                                <p className="order_price"><b>{stringPrice(el.ordered_product_sell_price)}</b>원 / <b>{el.quantity}</b>개</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      })}
                      {!isproduct && <li className="order_list_content">
                        <div className="order_list_content_in">
                          <div className="checks full_check">
                            <input type="checkbox" name="" id="chk_item2" onClick="" />
                            <label htmlFor="chk_item2"></label>
                          </div>
                          <div className="order_list_content_img">
                            <img src={product.item.product[0].main_img} alt={product.item.product[0].name}/>
                            <div className="order_list_content_p">
                              <p className="order_title">{product.item.product_name}</p>
                              <p className="order_price"><b>{stringPrice(product.item.product[0].price)}</b>원 / <b>{product.item.product_amount}</b>개</p>
                            </div>
                          </div>
                        </div>
                      </li>}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <p className="next_btn_wrap">
              <button onClick={showStep2} className="step1_btn next_btn">
                다음 { isPc ? <GrNext /> : <RiArrowRightSLine size={20} />}
              </button>
            </p>
            <div className="mypage_guide">
              <p className="guide_tit">교환 및 반품 안내</p>
              <p><span><BsDot /></span><span>단순 변심으로 인한 교환 및 반품 요청의 경우 미개봉 제품만 7일 이내 가능하며, 왕복 택배비 5,000원은 고객 부담입니다.</span></p>
              <p><span><BsDot /></span><span>상품 불량, 오배송의 경우 상품 배송일로부터 10일 이내 교환 및 반품이 가능하며, 자세한 사항은 고객센터로 문의 부탁드립니다.</span></p>
              <p><span><BsDot /></span><span>상품 불량의 경우 고객센터를 통한 불량 검수 후 정상 처리 여부가 결정 됩니다.</span></p>
              <p><span><BsDot /></span><span>단순변심으로 인한 패키지 상품의 부분 환불이 불가한 점 유의 부탁드립니다.</span></p>
              <p><span><BsDot /></span><span>개인적인 사용감을 포함한 단순 변심 및 명백한 사용자 과실로 판단되는 경우에는 반품 불가합니다. </span></p>
            </div>
          </div>
          <div className={step2 ? "step2_wrap custom_scroll active" : "step2_wrap custom_scroll"}>
            <div className="step_box">
              <p className="sub_tit">교환/반품 사유 선택</p>
                <select className="refund_select" name="refund_select" required {...register("refund_select")}>
                    <option value="" disabled selected hidden>교환/반품 사유를 선택해 주세요.</option>
                    <option>구매의사 취소</option>
                    <option>색상 및 사이즈 변경</option>
                    <option>다른 상품 잘못주문</option>
                    <option>서비스 및 상품 불만족</option>
                    <option>배송 지연</option>
                    <option>배송 누락</option>
                    <option>상품 품절</option>
                    <option>상품 파손</option>
                    <option>상품 정보 상이</option>
                    <option>오배송</option>
                </select>
                <textarea placeholder="[필수] 상세사유를 입력해 주세요." rows="5" name="refund_content" required {...register("refund_content")}></textarea>
            </div>
            {item.pay_method !== "card" ? <>
              {isMobile && <p className="noArea"></p>}
              <div className="step_box">
                <p className="sub_tit">
                  환불 계좌 정보 입력
                  { isPc && <span>원결제 방법을 통한 환불 불가시 입력해주신 계좌로 환불됩니다.</span> }
                </p>
                <div className="refund_account">
                  <div>
                    <p>은행명</p>
                    <p>
                      <select required {...register("bank_name")}>
                        <option disabled selected value="">은행을 선택해 주세요.</option>
                        <option value="국민">KB국민은행</option>
                        <option value="SC제일은행">SC제일은행</option>
                        <option value="경남은행">경남은행</option>
                        <option value="광주은행">광주은행</option>
                        <option value="기업은행">기업은행</option>
                        <option value="농협">농협</option>
                        <option value="대구은행">대구은행</option>
                        <option value="부산은행">부산은행</option>
                        <option value="산업은행">산업은행</option>
                        <option value="새마을금고">새마을금고</option>
                        <option value="수협">수협</option>
                        <option value="신한은행">신한은행</option>
                        <option value="신협">신협</option>
                        <option value="외환은행">외환은행</option>
                        <option value="우리은행">우리은행</option>
                        <option value="우체국">우체국</option>
                        <option value="축협">축협</option>
                        <option value="카카오뱅크">카카오뱅크</option>
                        <option value="케이뱅크">케이뱅크</option>
                        <option value="하나은행">하나은행</option>
                        <option value="한국씨티은행">한국씨티은행</option>
                        <option value="토스뱅크">토스뱅크</option>
                      </select>
                    </p>
                  </div>
                  <div><p>계좌번호</p><p><input type="text" name="bank_number" {...register("bank_number")} /></p></div>
                  <div><p>예금주</p><p><input type="text" name="bank_owner" {...register("bank_owner")} /></p></div>
                  { isMobile && <p className="refund_desc">원결제 방법을 통한 환불 불가시 입력해주신 계좌로 환불됩니다.</p> }
                </div>
              </div>
            </> : null}
            <p className="next_btn_wrap">
              <button onClick={showStep1} className="step1_btn prev_btn">
                { isPc ? <GrPrevious /> : <RiArrowLeftSLine size={20} />} 이전
              </button>
              <button onClick={showStep3} className="step1_btn next_btn">
                다음 { isPc ? <GrNext /> : <RiArrowRightSLine size={20} />}
              </button>
            </p>
          </div>
          <div className={step3 ? "step3_wrap custom_scroll active" : "step3_wrap custom_scroll"}>
            <div className="step_box">
              <p className="sub_tit">교환/반품 방법 선택</p>
              <div className="agree_box">
                <div className="checks full_check">
                    <input type="checkbox" name="" id="chk_delivery" checked={true} />
                    <label htmlFor="chk_delivery">쇼핑몰 지정반품 택배 (롯데택배)</label>
                </div>
              </div>
            </div>
            {isMobile && <p className="noArea"></p>}
            <div className="step_box">
              <p className="sub_tit">상품 수거지 확인<button onClick={() => {
                setValue("userName", "");
                setValue("zoneCode", "");
                setValue("address", "");
                setValue("addressDetail", "");
                setValue("p2", "");
                setValue("p3", "");
              }}>수정</button></p>
              <div className="address_form">
                  <div className="sub_cont_wrap address_info">
                  <div className="row">
                    <div className="form_title">이름</div>
                    <div className="form_cont"><input type="text" name="userName" {...register("userName")}  /></div>
                  </div>
                  <div className="row address">
                    <div className="form_title">주소</div>
                    <div className="form_cont">
                      <p><input type="text" id="Address1" name="zoneCode" {...register("zoneCode")} disabled /><Button size="small" color="white" outline className="post_num" onClick={() => {setPostCodePopup(!postCodePopup)}}>우편번호</Button></p>
                      <p><input type="text" id="Address2" name="address" {...register("address")} disabled /></p>
                      <p><input type="text" id="Address3" name="addressDetail" {...register("addressDetail")} placeholder="상세주소를 입력하세요." /></p>
                    </div>
                  </div>
                  <div className="row phone">
                    <div className="form_title">핸드폰 번호</div>
                    <div className="form_cont">
                      <p>
                        <select name="p1" {...register("p1")}>
                          <option>010</option>
                          <option>011</option>
                          <option>012</option>
                          <option>013</option>
                        </select>
                        <span>-</span>
                        <input type="tel" maxLength={4} name="p2" {...register("p2")} />
                        <span>-</span>
                        <input type="tel" maxLength={4} name="p3" {...register("p3")} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && <p className="noArea"></p>}
            <div className="step_box">
              <p className="sub_tit">교환/반품 상품 도착지 확인</p>
              <div className="address_box">
                <p>
                  바램시스템<br />
                  1670.8921<br />
                  (34002) 대전 유성구 국제과학 22로 44 (구룡동) 바램시스템
                </p>
              </div>
            </div>
            {isMobile && <p className="noArea"></p>}
            <div className="button_wrap">
              {checkUserName !== "" && checkPhone[1] !== "" && checkPhone[2] !== "" && checkAddress[0] !== undefined && checkAddress[1] !== "" ?
                  <Button size="small" onClick={handleSubmit(onSubmit)}>신청하기</Button> :
                  <Button size="small" color="gray">신청하기</Button>
              }

            </div>
          </div>
      </Dialog>
      <PostCode
          setValue={setValue}
          visible={postCodePopup}
          setPostCodePopup={setPostCodePopup}
          onCancel={() => setPostCodePopup(!postCodePopup)}
      />
    </ReqRefundDialog>
	);
}

const ReqRefundDialog = styled.div`
  display: ${({ visible }) => visible ? 'flex' : 'none'};
  @media only screen and (max-width: 960px) {
    padding-bottom: 30px;

    & .step_box {
      padding: 30px 15px;
    }
  }
  
  & .dialog_wrap {
    max-height: 90vh;
    box-sizing: border-box;
    padding: 0;

    & .dialog_inner {
      overflow-y: initial;
      max-height: 100%;
      padding: 30px 4px 4px;
    }

    @media only screen and (max-width: 960px) {
      overflow-y: hidden;
      width: 100%;
      max-width: 540px;
      max-height: 100vh;
      padding-bottom: 0 !important;
      & .dialog_inner {
        overflow-y: auto;
        height: 100vh;
        padding: 0!important;
      }
    }
  }

  & h3 {
    display: none;

    @media only screen and (max-width: 960px) {
      display: block;
    }
  }

  & .process {
    padding-bottom: 30px;
    & ul {
      display: flex;
      justify-content: center;
      & li {
        line-height: 22px;
        color: #515355;
        font-size: 0.938rem;
        &:not(:last-child) {
          padding-right: 28px;
          background: url(${arr_more}) no-repeat right 10px top 7px;
          background-size: 6px auto;
        }
        &.active {
          font-weight: 500;
          color: #1EA0FA;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      padding-bottom: 0;
      & ul {
        padding: 20px 0;
        & li {
          line-height: 19.5px;
          font-size: 0.813rem;
          &:not(:last-child) {
            padding-right: 23px;
            background: url(${arr_more}) no-repeat right 8px top 5px;
            background-size: 5px auto;
          }
        }
      }
    }
  }

  & .sub_tit {
    margin: 30px 0 10px;
    line-height: 24px;
    color: #222;
    font-weight: 500;
    line-height: 24px;
    @media only screen and (max-width: 960px) {
      margin: 0 0 20px 0;
      font-size: 1.063rem;
      line-height: 25px;
    }
  }

  & .step1_wrap {
    display: none;
    padding: 0 46px 30px;
    overflow-y: auto;
    max-height: calc(90vh - 100px);
    &.active {
      display: block;
    }
    & .req_type_wrap {
      display: flex;
      justify-content: center;
      & > div:first-child {
        position: relative;
        padding-right: 63px;
        margin-right: 63px;
        &::before {
          position: absolute;
          content: '';
          right: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background: #eee;
        }  
      }
    }
    & .order_lists{
      & ul > li.order_lists_list  {
        box-sizing: border-box;
        margin: 0;
        ul {
          border: 1px solid #ccc;
          & li.order_list_content{
            padding: 0 15px !important;

            & .checks {
              margin: 0 5px;
            }

            &:not(:last-child) {
              & .order_list_content_in {
                border-bottom: 1px solid #E1E2E3;    
              }
            }
            & .order_list_content_in {
              display: flex;
              align-items: center;
              padding: 15px 0 !important;
              
              & .order_list_content_img{
                width: 80px;
                display: flex;
                align-items: center;
                flex-grow: 1;
                
                img {
                  display: inline-block;
                  box-sizing: border-box;
                  width: 80px;
                  height: 80px;
                  margin-right: 20px;
                }
                
                & .order_list_content_p{
                  p:not(:first-child) {
                    margin-top: 4px;
                  }
                  
                  p.order_title {
                    font-size: 15px;
                    color: #222;
                    line-height: 22px;
                  }

                  p.order_option {
                    font-size: 14px;
                    line-height: 21
                    px;
                    color: #555;
                  }

                  p.order_price {
                    font-size: 14px;
                    line-height: 21px;
                    color: #555;
                    & b {
                        font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .mypage_guide {
      padding: 20px;
      margin-top: 20px;
      & .guide_tit {
        font-size: 13px;
        margin-bottom: 10px;
        line-height: 19px;
        font-weight: 500;
      }
      & span {
        font-size: 13px;
        line-height: 19px;
      }
    }

    @media only screen and (max-width: 960px) {
      padding: 0 0 30px 0;
      height: calc(90vh - 62px);
      max-height: calc(90vh - 62px);
      & .req_type_wrap {
        margin-bottom: 33px;
        & > div:first-child {
          padding-right: 50px;
          margin-right: 50px;
        }
      }
      & .step_box {
        padding-bottom: 0;
        & .sub_tit {
          margin-bottom: 10px;
        }
      }
      & .order_lists{
        background: none;
        padding-bottom: 0;
        & ul > li.order_lists_list  {
          ul {
            border: none;
            & li.order_list_content{
              padding: 0 !important;

              & .checks {
                margin: 0;
              }

              &:not(:last-child) {
                & .order_list_content_in {
                  border-bottom: 1px solid #EDEDED;    
                }
              }
              & .order_list_content_in {
                padding: 20px 0 !important;
                align-items: flex-start;
                
                & .order_list_content_img{
                  align-items: flex-start;
                  width: 75px;
                  
                  img {
                    width: 75px;
                    height: 75px;
                    margin-right: 15px;
                  }
                  
                  & .order_list_content_p{
                    p:not(:first-child) {
                      margin-top: 4px;
                    }
                    
                    p.order_title {
                      font-size: 1rem;
                    }

                    p.order_option {
                      font-size: 0.813rem;
                      line-height: 18px;
                      color: #888;
                    }

                    p.order_price {
                      font-size: 0.813rem;
                      line-height: 18px;
                      color: #222;
                      & b {
                          font-weight: 700;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      & .mypage_guide {
        padding: 20px;
        margin-top: 10px;
        & .guide_tit {
          font-size: 0.813rem;
          margin-bottom: 10px;
          line-height: 19px;
          font-weight: 500;
        }
        & span {
          font-size: 13px;
          line-height: 19px;
        }
        @media only screen and (max-width: 960px) {
          padding: 30px 15px;
          margin-top: 10px;
          & .guide_tit {
            font-size: 0.875rem;
            line-height: 21px;
          }
          & span {
            font-size: 0.75rem;
            line-height: 18px;
            letter-spacing: -0.01em;
          }
        }
      }
    }
  }

  & .step2_wrap {
    display: none;
    padding: 0 46px 30px;
    overflow-y: auto;
    max-height: calc(90vh - 100px);
    &.active {
      display: block;
    }
    & .step_box:first-child {
      margin-top: 10px;
      & .sub_tit {
        margin-top: 0;
      }
    }

    & .sub_tit {
      & span {
        display: block;
        margin-top: 4px;
        font-size: 13px;
        line-height: 19px;
        color: #888;
        font-weight: 400;
      }
    }

    & select {
      margin-bottom: 10px;
      font-size: 0.938rem;
      line-height: 22px;
      padding: 10px 15px;
      border: 1px solid #ddd;
      background: url(${sublist_open}) no-repeat right 15px center;
    }
    & textarea {
      font-size: 0.938rem;
      line-height: 22px;
      padding: 10px 15px;
      border: 1px solid #ddd;
      &::placeholder {
        font-size: 0.938rem;
        line-height: 22px;
        color: #999;
      }
    }
    & .refund_account {
      margin-top: 16px;
      & div {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 10px;
        }
        & p:first-child {
          width: 95px;
          font-size: 15px;
          line-height: 22px;
          color: #222
        }
        & p:last-child {
          width: calc(100% - 95px);
          & input {
            padding: 10px 15px;
            border: 1px solid #ddd;
            font-size: 15px;
            color: #222;
            line-height: 21px;
          }
          & select {
            margin-bottom: 0;
          }
        }
      }
    }

    @media only screen and (max-width: 960px) {
      padding: 0 0 30px 0;
      max-height: calc(90vh - 62px);
      & .step_box:first-child {
        margin-top: 0;
      }
      & select {
        border: 1px solid #ccc;
        background: url(${m_sublist_open}) no-repeat right 15px center;
        background-size: 12px 6px;
      }
      & textarea {
        border: 1px solid #ccc;
      }
      & .refund_account {
        margin-top: 0;
        & div {
          flex-direction: column;
          align-items: flex-start;
          & p:first-child {
            width: 100%;
          }
          & p:last-child {
            width: 100%;
            margin-top: 10px;
            & input {
              border: 1px solid #ccc;
            }
          }
        }

        & .refund_desc {
          margin-top: 10px;
          font-size: 0.75rem;
          letter-spacing: -0.02em;
          line-height: 18px;
          color: #888;
        }
      }
      & .next_btn_wrap {
        margin-top: 0;
      }
    }
  }

  & .step3_wrap {
    display: none;
    padding: 0 46px 30px;
    overflow-y: auto;
    max-height: calc(90vh - 100px);
    &.active {
      display: block;
    }
    & .step_box:first-child {
      margin-top: 10px;
      & .sub_tit {
        margin-top: 0;
      }
    }
    & .sub_tit {
      display: flex;
      align-items: center;
      & button {
        margin-left: auto;
        color: #888;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
      }
    }

    & .agree_box {
      padding: 20px;
      border: 1px solid #ddd;

      & label {
        font-size: 15px;
      }
    }
    & .address_form {
      margin-top: 20px;

      & input, & select {
        padding: 10px 15px;
        font-size: 15px;
        color: #222;
        line-height: 21px;
        &::placeholder {
          font-size: 15px;
        }
      }
      & .row {
        display: flex;
        & .form_title {
          width: 95px;
          padding-top: 12px;
          font-size: 15px;
        }
        & .form_cont {
          width: calc(100% - 95px);
        }
        &.address {
          margin-top: 10px;
          & p:not(:first-child) {
            margin-top: 5px;
          }
          & p:first-child {
            display: flex;
            & input {
              width: calc(100% - 115px);
            }
          }
          & .post_num {
            width: 110px;
            margin-left: 5px;
            font-size: 15px;
            font-weight: 400;
          }
        }

        &.phone {
          margin-top: 10px;
          & .form_cont {
            & p {
              display: flex;
              align-items: center;
              & span {
                display: inline-block;
                width: 18px;
                text-align: center;
                color: #222;
              }
              & select {
                width: 90px;
              }
              & input {
                width: calc((100% - 126px) / 2);
              }
            }
          }
        }
      }
    }

    & .address_box {
      background: #F7F7F7;
      padding: 20px;
      & p {
        line-height: 21px;
        font-size: 14px;
        color: #222;
      }
    }

    & .button_wrap {
      margin-bottom: 10px;
      text-align: center;
      & button {
        width: 160px;
        border-radius: 4px;
      }
    }

    @media only screen and (max-width: 960px) {
      padding: 0 0 30px 0;
      max-height: calc(90vh - 62px);
      & .step_box:first-child {
        margin-top: 0;
      }
      & .agree_box {
        padding: 0;
        border: none;

        & label {
          font-size: 15px;
        }
      }
      & .address_form {
        margin-top: 0;

        & input, & select {
          padding: 11px 14px;
        }
        & .row {
          flex-direction: column;
          & .form_title {
            width: 100%;
            padding-top: 0;
            font-size: 0.938rem;
          }
          & .form_cont {
            width: 100%;
            margin-top: 10px;
          }
          &.address {
            margin-top: 20px;
            & p:first-child {
              & input {
                width: calc(100% - 105px);
              }
            }
            & .post_num {
              width: 100px;
            }
          }

          &.phone {
            margin-top: 20px;
            & .form_cont {
              & p {
                display: flex;
                align-items: center;
                & span {
                  display: inline-block;
                  width: 18px;
                  text-align: center;
                  color: #222;
                }
                & select, & input {
                  width: calc((100% - 36px) / 3);
                }
              }
            }
          }
        }
      }

      & .address_box {
        background: #F7F7F7;
        padding: 20px;
        & p {
          line-height: 21px;
          font-size: 14px;
          color: #222;
        }

        @media only screen and (max-width: 960px) {
          background: none;
          padding: 0;
          & p {
            line-height: 22px;
            font-size: 0.938rem;
          }
        }
      }

      & .button_wrap {
        margin-bottom: 0;
        & button {
          width: 170px;
          border-radius: 4px;
        }
        @media only screen and (max-width: 960px) {
          padding: 0 15px;
          & div {
            width: 100%;
            height: 55px;
            font-size: 1.125rem;
            font-weight: 700;
          }
        }
      }
    }
  }

  & .next_btn_wrap {
    display: flex;
    margin-top: 20px;    
    & button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 40px;
      border-radius: 40px;
      border: 1px solid #1EA0FA;
      color: #1EA0FA;
      font-weight: 500;
      line-height: 22px;
      font-size: 15px;
      &.prev_btn svg {
        margin-right: 8px;
      }
      &.next_btn {
        margin-left: auto;
        & svg {
          margin-left: 8px;
        }
      }
      & svg {
        font-size: 12px;
        & polyline {
          stroke: #1EA0FA;
        }
      }
    }
    @media only screen and (max-width: 960px) {
      margin: 30px 15px 0;
      & .next_btn {
        padding-left: 4px;
        & svg {
          margin-left: 3px;
        }
      }
    }
  }

  &  .button_wrap {
    display: flex;
    margin: 30px auto 0;
    justify-content: center;
    & div {
      width: 170px;
      border-radius: 4px;
    }
  }

  & .ButtonWrap {
    display: none;
  }
`

export default ReqRefundPopup;